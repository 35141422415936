<template>
  <div class="parameter-group-pane">
    Quisque cursus, metus vitae pharetra auctor, sem massa mattis sem, at
    interdum magna augue eget diam. Vestibulum ante ipsum primis in faucibus
    orci luctus et ultrices posuere cubilia Curae; Morbi lacinia molestie dui.
    Praesent blandit dolor. Sed non quam. In vel mi sit amet augue congue
    elementum. Morbi in ipsum sit amet pede facilisis laoreet. Donec lacus nunc,
    viverra nec, blandit vel, egestas et, augue. Vestibulum tincidunt malesuada
    tellus. Ut ultrices ultrices enim. Curabitur sit amet mauris. Morbi in dui
    quis est pulvinar ullamcorper. Nulla facilisi. Integer lacinia sollicitudin
    massa.
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
</style>