export function calculateIntensity(value, min, max) {
  var intensity = ((value - min) / (max - min)) * 255;
  return Math.max(0, Math.min(intensity, 255));
}

// thanks! https://stackoverflow.com/questions/1669190/find-the-min-max-element-of-an-array-in-javascript
export function arrayMin(arr) {
  var len = arr.length, min = Infinity;
  while (len--) {
    if (arr[len] < min) {
      min = arr[len];
    }
  }
  return min;
}

// thanks! https://stackoverflow.com/questions/1669190/find-the-min-max-element-of-an-array-in-javascript
export function arrayMax(arr) {
  var len = arr.length, max = -Infinity;
  while (len--) {
    if (arr[len] > max) {
      max = arr[len];
    }
  }
  return max;
}