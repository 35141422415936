<template>
  <div class="parameter-group-pane">
    <div class="parameter-title">Subject</div>
    <el-select v-model="$store.state.parameters.subject" placeholder="Select">
      <el-option
        v-for="item in $store.state.subjects"
        :key="item.value"
        :label="item.label"
        :value="item.value"
        :disabled="item.disabled"
      ></el-option>
    </el-select>
    <div class="parameter-title">Pulse Sequence</div>
    <el-radio-group
      v-model="$store.state.parameters.pulse_sequence"
      size="medium"
    >
      <el-radio-button label="Flash"></el-radio-button>
      <el-radio-button label="EPI"></el-radio-button>
      <el-radio-button label="Field Map"></el-radio-button>
    </el-radio-group>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
</style>