<template>
  <el-tabs class="inner-tab" type="card">
    <el-tab-pane label="Part 1">
      <ul class="parameter-group">
        <li>
          <label>Bandwidth</label>
          <el-input-number
            size="mini"
            :step="1"
            v-model="$store.state.parameters.bandwidth"
          ></el-input-number>
          Hz/pixel
        </li>
      </ul>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
</style>