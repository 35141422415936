<template>
  <svg-sprite />
  <el-container>
    <el-header height="">
      <svg-icon icon="mri" />
      Olmanlab MRI Simulator
    </el-header>
    <el-main>
      <el-row v-if="$store.state.isLoading" class="loader">
        <el-col :span="24" class="loader-text">Rendering Scan Images</el-col>
        <el-col :span="24">
          <div class="col-md-12">
            <div class="preloader3"></div>
          </div>
        </el-col>
      </el-row>
      <el-row v-if="!$store.state.isLoading" class="orthogonal-viewer">
        <el-col :span="8">
          <div class="grid-content">
            <PlaneViewer
              class="coronal-viewer"
              :slice="$store.state.slices.coronal"
            ></PlaneViewer>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content">
            <PlaneViewer
              class="sagittal-viewer"
              :slice="$store.state.slices.sagittal"
            ></PlaneViewer>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content">
            <PlaneViewer
              class="axial-viewer"
              :slice="$store.state.slices.axial"
            ></PlaneViewer>
          </div>
        </el-col>
      </el-row>
      <el-row class="control-pane">
        <el-col :span="9">
          <div class="grid-content viewer-controls">
            <div
              class="slice-controls"
              v-if="$store.state.imageData.length > 0"
            >
              <div class="slice-selectors">
                <SliceSelector
                  label="Coronal Slice"
                  v-model="$store.state.slices.coronal.currentSlice"
                  :min="1"
                  :max="Math.max($store.state.slices.coronal.sliceCount, 1)"
                ></SliceSelector>
                <SliceSelector
                  label="Sagittal Slice"
                  v-model="$store.state.slices.sagittal.currentSlice"
                  :min="1"
                  :max="Math.max($store.state.slices.sagittal.sliceCount, 1)"
                ></SliceSelector>
                <SliceSelector
                  label="Axial Slice"
                  v-model="$store.state.slices.axial.currentSlice"
                  :min="1"
                  :max="Math.max($store.state.slices.axial.sliceCount, 1)"
                ></SliceSelector>
                <SliceSelector
                  label="Zoom Factor"
                  v-model="$store.state.zoomFactor"
                  :min="0.5"
                  :max="5.0"
                  :step="0.1"
                ></SliceSelector>
              </div>
            </div>
            <div class="scanner-controls">
              <el-button
                type="primary"
                icon="el-icon-video-play"
                v-if="!$store.state.isLoading"
                v-on:click="$store.dispatch('fetchImage')"
              >
                Start Scan
              </el-button>
            </div>
          </div>
        </el-col>
        <el-col :span="15">
          <div class="grid-content parameter-pane">
            <el-tabs
              id="outer-tab-group"
              type="border-card"
              tab-position="bottom"
              v-model="$store.state.parameterActiveTab"
            >
              <el-tab-pane label="Program" name="Program">
                <Program></Program>
              </el-tab-pane>
              <el-tab-pane label="Routine" name="Routine">
                <Routine></Routine>
              </el-tab-pane>
              <el-tab-pane label="Contrast" name="Contrast">
                <Contrast></Contrast>
              </el-tab-pane>
              <el-tab-pane label="Resolution" name="Resolution">
                <Resolution></Resolution>
              </el-tab-pane>
              <el-tab-pane label="Geometry" name="Geometry">
                <Geometry></Geometry>
              </el-tab-pane>
              <el-tab-pane label="System" name="System">
                <System></System>
              </el-tab-pane>
              <el-tab-pane label="Physio" name="Physio">
                <Physio></Physio>
              </el-tab-pane>
              <el-tab-pane label="Inline" name="Inline">
                <Inline></Inline>
              </el-tab-pane>
              <el-tab-pane
                label="BOLD"
                name="Bold"
                v-if="$store.state.parameters.pulse_sequence == 'EPI'"
              >
                <Bold></Bold>
              </el-tab-pane>
              <el-tab-pane label="Sequence" name="Sequence">
                <Sequence></Sequence>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-col>
      </el-row>
    </el-main>
    <el-footer height="">
      <div style="display: flex">
        <div style="display: flex; flex-grow: 1">
          {{ $store.getters.statusMessage }}
        </div>
        <div style="display: flex">
          <span id="time">{{ currentTime }}</span>
        </div>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import SvgIcon from './components/SvgIcon'
import SvgSprite from './components/SvgSprite'
import PlaneViewer from './components/PlaneViewer'
import SliceSelector from './components/SliceSelector'
import Program from './components/parameterSelection/Program'
import Routine from './components/parameterSelection/Routine'
import Contrast from './components/parameterSelection/Contrast'
import Resolution from './components/parameterSelection/Resolution'
import Geometry from './components/parameterSelection/Geometry'
import System from './components/parameterSelection/System'
import Physio from './components/parameterSelection/Physio'
import Inline from './components/parameterSelection/Inline'
import Bold from './components/parameterSelection/Bold'
import Sequence from './components/parameterSelection/Sequence'

export default {
  name: 'MRISimulator',
  components: {
    SvgIcon,
    SvgSprite,
    PlaneViewer,
    SliceSelector,
    Program,
    Routine,
    Contrast,
    Resolution,
    Geometry,
    System,
    Physio,
    Inline,
    Bold,
    Sequence
  },
  data() {
    return {
      currentTime: 'ABCD'
    }
  },
  mounted: function () {
    this.$store.dispatch('fetchImage')
    this.updateTime()
    this.timer = setInterval(this.updateTime, 1000)
  },
  methods: {
    updateTime: async function () {
      var now = new Date()
      await this.$nextTick()
      this.currentTime =
        now.toLocaleDateString() + ' ' + now.toLocaleTimeString()
    }
  }
}
</script>

<style lang="scss">
$border-color: #444;
$axial-color: #a0d468;
$coronal-color: #4fc1e9;
$sagittal-color: #ec87c0;

body,
html {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  background-color: #343a40;
  color: #ffffff;
}

.el-container {
  height: 100vh;
  width: 100vw;
  padding: 0;
}

.el-header {
  line-height: 40px;
  font-weight: 600;
  font-size: 1.5em;
  padding: 5px;
  border-bottom: 1px solid $border-color;
}

.el-header > .icon {
  width: 32px;
  height: 32px;
  margin-right: 5px;
}

.el-main {
  flex-grow: 1;
  padding: 0px;
  display: flex;
  flex-direction: column;
}

.el-footer {
  padding: 5px;
  border-top: 1px solid $border-color;
}

.control-pane {
  background-color: aliceblue;
  display: flex;
  overflow: auto;
}

.plane > .plane-label {
  color: #e5de13;
  font-weight: 400;
  text-align: left;
  z-index: 1000;
  left: 5px;
  right: 0;
  margin: auto;
  position: absolute;
  top: 5px;
}

.plane > .overlay {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  z-index: 999;
}

.plane > .image {
  margin-left: auto;
  margin-right: auto;
  min-width: 100%;
  min-height: 100%;
  box-sizing: content-box;
  z-index: 998;
}

.plane > .image > img {
  height: 25vh;
}

.content-pane {
  box-sizing: content-box;
  padding: 10px;
}

.control-pane {
  flex-grow: 1;
  color: #000;
}

#ax-handle {
  width: 3em;
  height: 1.6em;
  top: 50%;
  margin-top: -0.8em;
  text-align: center;
  line-height: 1.6em;
}

#cor-handle {
  width: 3em;
  height: 1.6em;
  top: 50%;
  margin-top: -0.8em;
  text-align: center;
  line-height: 1.6em;
}

#sag-handle {
  width: 3em;
  height: 1.6em;
  top: 50%;
  margin-top: -0.8em;
  text-align: center;
  line-height: 1.6em;
}

#zoom-handle {
  width: 3em;
  height: 1.6em;
  top: 50%;
  margin-top: -0.8em;
  text-align: center;
  line-height: 1.6em;
}

.preloader3 {
  height: 80px;
  width: 80px;
  margin: 20px auto 0;
  position: relative;
}
.preloader3:after,
.preloader3:before {
  content: '';
  height: 100%;
  width: 100%;
  border: 9px solid #fff;
  border-color: transparent #fff;
  position: absolute;
  left: 0;
  top: 0;
  animation: spin 2.2s linear 0s infinite normal;
}
.preloader3:after {
  width: 50%;
  height: 50%;
  margin: auto;
  border-style: dotted;
  border-radius: 50px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  animation: spinback 1.5s linear 0s infinite normal;
}
@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinback {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}

.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  height: 100%;
  border-right: 1px solid $border-color;
}

.el-col:last-child > .grid-content {
  border-right: 0px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.slice-selector {
  float: right;
  width: 50%;
}

.slice-input {
  padding: 5px 10px 5px 10px;
  height: 40px;
}

.orthogonal-viewer {
  height: 40vh;
  min-height: 40vh;
  background-color: #000000;
  color: #ffffff;
  border-bottom: 1px solid $border-color;
}

.loader {
  height: 40vh;
  background-color: #000000;
  color: #ffffff;
  border-bottom: 1px solid $border-color;
  align-content: center;
}

.loader-text {
  font-size: 1.5em;
  font-weight: 600;
  text-align: center;
  padding: 5vh 0 5vh 0;
}

.parameter-pane {
  display: flex;
  height: 100%;
  width: 100%;
}

#outer-tab-group {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#outer-tab-group .el-tabs__content {
  display: flex;
  flex-grow: 1;
  padding: 0px;
}

#outer-tab-group .parameter-group-pane {
  padding: 10px;
}

#outer-tab-group .parameter-title {
  font-weight: 600;
  font-size: 1em;
  margin-bottom: 10px;
}

#outer-tab-group .parameter-title:not(:first-child) {
  margin-top: 10px;
}

.viewer-controls {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px;
}

.slice-controls {
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.scanner-controls {
  justify-content: flex-end;
  display: flex;
  padding: 10px;
}

#inner-tab-contrast {
  width: 100%;
}

#pane-Contrast {
  display: flex;
  width: 100%;
}

#pane-Resolution {
  display: flex;
  width: 100%;
}

#pane-Sequence {
  display: flex;
  width: 100%;
}

#pane-System {
  display: flex;
  width: 100%;
}

.coronal-viewer .title {
  color: $coronal-color;
}

.sagittal-viewer .title {
  color: $sagittal-color;
}

.axial-viewer .title {
  color: $axial-color;
}

// Parameter Styles
.inner-tab {
  width: 100%;
}

.el-tab-pane {
  width: 100%;
}

.parameter-group {
  width: 100%;
  list-style-type: none;
  padding: 5px;
  margin: 0px;
}

.parameter-group li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.parameter-group > li:not(:last-child) {
  margin-bottom: 10px;
}

.parameter-group li label,
.parameter-group li p {
  flex: 0 0 170px;
  padding: 8px;
  font-weight: 600;
  text-align: right;
}

.parameter-group li p {
  margin: 0;
}

.parameter-group li > div {
  margin-right: 10px;
}
</style>
