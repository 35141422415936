import NiftiReader from "nifti-reader-js";
import { calculateIntensity, arrayMax, arrayMin } from "../utils";

export default class NiftiParser {
  constructor(data) {
    this._url = data.url;
    this._buffer = data.buffer;
    this._header = null;
    this._extension = null;

    try {
      if (NiftiReader.isCompressed(this._buffer)) {
        this._buffer = NiftiReader.decompress(this._buffer);
      }
      this._header = NiftiReader.readHeader(this._buffer);
    } catch (error) {
      console.log("Well, that's just great.");
    }
  }

  header() {
    return this._header;
  }

  extension() {
    return this._extension;
  }

  pixelData(timestep = 0) {
    let dv = new DataView(this._buffer);

    const pixelCount = this._header.dims[1] * this._header.dims[2] * this._header.dims[3];
    let frameOffset =
      this._header.vox_offset +
      ((pixelCount * this._header.numBitsPerVoxel) / 8) * timestep;

    var arr = null;
    if (this._header.datatypeCode === 2) {
      arr = Uint8Array;
    } else if (this._header.datatypeCode === 4) {
      arr = Int16Array;
    } else if (this._header.datatypeCode === 8) {
      arr = Int32Array;
    } else if (this._header.datatypeCode === 16) {
      arr = Float32Array;
    } else if (this._header.datatypeCode === 32) {
      arr = Float64Array;
    } else if (this._header.datatypeCode === 64) {
      arr = Float64Array;
    } else if (this._header.datatypeCode === 256) {
      arr = Int8Array;
    } else if (this._header.datatypeCode === 512) {
      arr = Uint16Array;
    } else if (this._header.datatypeCode === 768) {
      arr = Uint32Array;
    } else if (this._header.datatypeCode === 1024) {
      // eslint-disable-next-line no-undef
      arr = BigInt64Array;
    } else if (this._header.datatypeCode === 1280) {
      // eslint-disable-next-line no-undef
      arr = BigUint64Array;
    }

    if (this._header.cal_min === 0 || this._header.cal_max === 0) {
      let voxelArray = new arr(this._buffer, frameOffset, pixelCount);
      let min = arrayMin(voxelArray);
      let max = arrayMax(voxelArray);

      return voxelArray.map(val => calculateIntensity(val, min, max));
    } else {
      let voxelArray = new arr(this._buffer, frameOffset, pixelCount).map(val => calculateIntensity(val, this._header.cal_min, this._header.cal_max));
      return voxelArray;
    }


  }

  rawData() {
    return this._buffer;
  }


}
