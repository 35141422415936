<template>
  <el-tabs class="inner-tab" type="card">
    <el-tab-pane label="Common">
      <ul class="parameter-group">
        <li>
          <label>TR</label>
          <el-input-number
            size="mini"
            controls-position="right"
            v-model="$store.state.parameters.tr"
            :precision="1"
            :step="0.1"
          />
          ms
        </li>
        <li>
          <label>TE</label>
          <el-input-number
            size="mini"
            controls-position="right"
            v-model="$store.state.parameters.te"
            :precision="2"
            :step="0.01"
          />
          ms
        </li>
        <li>
          <label>Magn. Preparation</label>
          <el-select
            v-model="$store.state.parameters.mag_prep"
            placeholder="Select"
          >
            <el-option
              v-for="item in preps"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </li>
        <li v-if="$store.state.parameters.mag_prep != 0">
          <label>Inversion Time (TI)</label>
          <el-input-number
            size="mini"
            controls-position="right"
            v-model="$store.state.parameters.ti"
          />
          ms
        </li>
        <li>
          <label>Flip angle</label>
          <el-input-number
            size="mini"
            controls-position="right"
            v-model="$store.state.parameters.flip_angle"
          />
          deg
        </li>
      </ul>
    </el-tab-pane>
    <el-tab-pane label="Dynamic">
      <ul class="parameter-group">
        <li>
          <label>Reconstruction</label>
          <el-select
            v-model="$store.state.parameters.reconstruction"
            placeholder="Select"
          >
            <el-option
              v-for="item in reconstructions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </li>
      </ul>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
export default {
  data() {
    return {
      preps: [
        { value: 0, label: 'None' },
        { value: 1, label: 'Non-sel. IR' },
        { value: 2, label: 'Slice-selective IR' }
      ],
      reconstructions: [
        { value: 0, label: 'mag' },
        { value: 1, label: 'phase' },
        { value: 2, label: 'real' }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
</style>