<template>
  <ul class="parameter-group">
    <li>
      <label>Slices</label>
      <el-input-number
        size="mini"
        controls-position="right"
        v-model="$store.state.parameters.slices"
      />
    </li>
    <li>
      <label>Orientation</label>
      <el-select
        v-model="$store.state.parameters.orientation"
        placeholder="Select"
      >
        <el-option
          v-for="item in orientations"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :disabled="item.disabled"
        ></el-option>
      </el-select>
    </li>
    <li>
      <label>Phase Encode Direction</label>
      <el-select
        v-model="$store.state.parameters.phase_direction"
        placeholder="Select"
      >
        <el-option
          v-for="item in $store.getters.phaseDirections"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :disabled="item.disabled"
        ></el-option>
      </el-select>
    </li>
    <li>
      <label>FoV Read</label>
      <el-input-number
        size="mini"
        controls-position="right"
        v-model="$store.state.parameters.fov_read"
      />
      mm
    </li>
    <li>
      <label>FoV Phase</label>
      <el-input-number
        size="mini"
        controls-position="right"
        v-model="$store.state.parameters.fov_phase"
        :min="0"
        :max="100"
        :precision="1"
        :step="0.1"
      />
      %
    </li>
    <li>
      <label>Slice Thickness</label>
      <el-input-number
        size="mini"
        controls-position="right"
        v-model="$store.state.parameters.slice_thickness"
        :precision="2"
        :step="0.01"
      />
      mm
    </li>
    <li>
      <label>TR</label>
      <el-input-number
        size="mini"
        controls-position="right"
        v-model="$store.state.parameters.tr"
        :precision="1"
        :step="0.1"
      />
      ms
    </li>
    <li>
      <label>TE</label>
      <el-input-number
        size="mini"
        controls-position="right"
        v-model="$store.state.parameters.te"
        :precision="2"
        :step="0.01"
      />
      ms
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      orientations: [
        { value: 'coronal', label: 'Coronal' },
        { value: 'sagittal', label: 'Sagittal' },
        { value: 'transverse', label: 'Transverse' }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
</style>