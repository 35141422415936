<template>
  <div class="parameter-group-pane">
    On a real scanner, this tab has information about physiological monitoring
    options.
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
</style>