<template lang="en">
    <div class="selector-input">
        <label class="selector-label">{{ $props.label }}</label>
        <div class="selector">
            <div v-if=$props.displayEndpoints class="selector-bounds-label">{{ $props.min }}</div>
                <el-slider
                    class="selector-slider"
                    :min="min"
                    :max="max"
                    v-model="modelValue"
                    @update:modelValue="$emit('update:modelValue', $event)"
                    :step="step"
                ></el-slider>
                <div v-if="displayEndpoints" class="selector-bounds-label">{{ $props.max }}</div>
        </div>
    </div>      
</template>
<script>
export default {
  props: {
    modelValue: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    min: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    displayEndpoints: {
      type: Boolean,
      default: true
    },
    step: {
      type: Number,
      default: 1
    }
  },
  methods: {}
}
</script>
<style scoped lang="scss">
$label-padding: 15px;

.selector-input {
  display: flex;
  width: 100%;
  align-items: center;
}

.selector-label {
  flex: 0 0 33%;
}

.selector {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.selector-slider {
  display: flex;
  flex-grow: 1;
}

.selector-bounds-label {
  padding: 0 $label-padding 0 $label-padding;
}
</style>
