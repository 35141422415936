<template>
  <div :id="'renderer_' + slice.plane" class="renderer">
    <div class="img-container">
      <canvas ref="cvsImage" class="image"></canvas>
    </div>
    <div class="overlay" v-if="$store.state.isLoaded">
      <canvas ref="cvsCrosshair" class="crosshair"></canvas>
      <div class="title">
        <h4 :class="$attrs.class">{{ slice.label }}</h4>
        <p>{{ slice.currentSlice }} / {{ slice.sliceCount }} slices</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { getOverlayRenderer, getSliceRenderer } from '../modules/mriSim'

export default {
  name: 'PlaneViewer',
  data: function () {
    return {
      overlayRenderer: getOverlayRenderer(this.slice.plane),
      sliceRenderer: getSliceRenderer(this.slice.plane)
    }
  },
  props: {
    slice: {
      type: Object,
      required: true
    }
  },
  methods: {},
  computed: {
    ...mapState([
      'imageData',
      'imageHeader',
      'zoomFactor',
      'slices',
      'isLoaded'
    ])
  },
  watch: {
    slices: {
      deep: true,
      immediate: true,
      handler: function () {
        if (this.isLoaded) {
          this.$nextTick(function () {
            this.overlayRenderer()
          })
        }
      }
    },
    slice: {
      deep: true,
      immediate: true,
      handler: function () {
        if (this.isLoaded) {
          this.$nextTick(function () {
            this.sliceRenderer()
          })
        }
      }
    },
    zoomFactor: function () {
      if (this.isLoaded) {
        this.$nextTick(function () {
          this.overlayRenderer()
          this.sliceRenderer()
        })
      }
    }
  }
}
</script>

<style scoped>
* {
  margin: 0px;
  padding: 0px;
}

.renderer {
  height: 100%;
  width: 100%;
  position: relative;
}
.overlay {
  z-index: 100;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  padding: 0px;
}

.title {
  padding: 10px;
  z-index: 110;
}

.title h4 {
  margin-bottom: 5px;
}

.img-container {
  background-color: #000;
  width: 100%;
  height: 100%;
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
}

.crosshair {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
