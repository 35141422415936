import { createStaticVNode } from "vue";
import { NiftiParser } from "./parsers";

const coronalColor = "#4FC1E9";
const sagittalColor = "#EC87C0";
const axialColor = "#A0D468";
const axisMargin = 30;
const reticleSize = 20;

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function setPixel(img, x, y, r, g, b, a) {
  let index = x + y * img.width;
  img.data[index * 4 + 0] = r;
  img.data[index * 4 + 1] = g;
  img.data[index * 4 + 2] = b;
  img.data[index * 4 + 3] = a;
}


var mriSim = mriSim || {};

export async function getNifti(url) {
  let parser = null;

  let response = await fetch(url)
    .then(res => res.arrayBuffer())
    .then(buf => {
      var data = { "url": url, "buffer": buf };
      parser = new NiftiParser(data);
    });

  return {
    imageArrayBuffer: parser.pixelData(),
    imageHeader: parser.header(),
    imageExtension: parser.extension(),
    imageRawData: parser.rawData(),
  };
}

async function sagittalOverlayRenderer() {
  let overlay = this.$refs.cvsCrosshair,
    ctx = overlay.getContext('2d')

  overlay.width = overlay.parentElement.offsetWidth
  overlay.height = overlay.parentElement.offsetHeight
  var scaledWidth = (this.slice.sliceCount * this.zoomFactor),
    scaledHeight = (this.slice.sliceCount * this.zoomFactor),
    x =
      (overlay.width - (this.slice.sliceCount * this.zoomFactor)) / 2,
    y =
      (overlay.height - (this.slice.sliceCount * this.zoomFactor)) / 2

  let sliceWidth = scaledWidth / this.slices.coronal.sliceCount, // sagittal plane
    sliceHeight = scaledHeight / this.slices.axial.sliceCount // coronal plane

  ctx.beginPath()
  ctx.rect(
    (overlay.width - scaledWidth) / 2 +
    sliceWidth * (this.slices.coronal.currentSlice - 1),
    axisMargin,
    1, //sliceWidth,
    overlay.height - 2 * axisMargin
  )
  ctx.fillStyle = coronalColor
  ctx.fill()

  ctx.beginPath()
  ctx.rect(
    axisMargin,
    (overlay.height - scaledHeight) / 2 + scaledHeight - (sliceHeight * this.slices.axial.currentSlice),
    overlay.width - 2 * axisMargin,
    1 //sliceHeight
  )
  ctx.fillStyle = axialColor
  ctx.fill()

  ctx.clearRect((overlay.width - scaledWidth) / 2 +
    sliceWidth * (this.slices.coronal.currentSlice - 1) - reticleSize / 2, (overlay.height - scaledHeight) / 2 + scaledHeight - (sliceHeight * this.slices.axial.currentSlice) - reticleSize / 2, reticleSize, reticleSize)

}

async function coronalOverlayRenderer() {
  let overlay = this.$refs.cvsCrosshair,
    ctx = overlay.getContext('2d')

  overlay.width = overlay.parentElement.offsetWidth
  overlay.height = overlay.parentElement.offsetHeight
  var scaledWidth = (this.slice.sliceCount * this.zoomFactor),
    scaledHeight = (this.slice.sliceCount * this.zoomFactor),
    x =
      (overlay.width - (this.slice.sliceCount * this.zoomFactor)) / 2,
    y =
      (overlay.height - (this.slice.sliceCount * this.zoomFactor)) / 2

  let sliceWidth = scaledWidth / this.slices.sagittal.sliceCount, // sagittal plane
    sliceHeight = scaledHeight / this.slices.axial.sliceCount // coronal plane

  ctx.beginPath()
  ctx.rect(
    (overlay.width - scaledWidth) / 2 +
    sliceWidth * (this.slices.sagittal.currentSlice - 1),
    axisMargin,
    1, //sliceWidth,
    overlay.height - 2 * axisMargin
  )
  ctx.fillStyle = sagittalColor
  ctx.fill()

  ctx.beginPath()
  ctx.rect(
    axisMargin,
    (overlay.height - scaledHeight) / 2 + scaledHeight - (sliceHeight * this.slices.axial.currentSlice),
    overlay.width - 2 * axisMargin,
    1 //sliceHeight
  )
  ctx.fillStyle = axialColor
  ctx.fill()

  ctx.clearRect((overlay.width - scaledWidth) / 2 +
    sliceWidth * (this.slices.sagittal.currentSlice - 1) - reticleSize / 2, (overlay.height - scaledHeight) / 2 + scaledHeight - (sliceHeight * this.slices.axial.currentSlice) - reticleSize / 2, reticleSize, reticleSize)
}

async function axialOverlayRenderer() {
  let overlay = this.$refs.cvsCrosshair,
    ctx = overlay.getContext('2d')

  overlay.width = overlay.parentElement.offsetWidth
  overlay.height = overlay.parentElement.offsetHeight
  var scaledWidth = (this.slice.sliceCount * this.zoomFactor),
    scaledHeight = (this.slice.sliceCount * this.zoomFactor),
    x =
      (overlay.width - (this.slice.sliceCount * this.zoomFactor)) / 2,
    y =
      (overlay.height - (this.slice.sliceCount * this.zoomFactor)) / 2

  let sliceWidth = scaledWidth / this.slices.sagittal.sliceCount, // sagittal plane
    sliceHeight = scaledHeight / this.slices.coronal.sliceCount // coronal plane

  ctx.beginPath()
  ctx.rect(
    (overlay.width - scaledWidth) / 2 +
    sliceWidth * (this.slices.sagittal.currentSlice - 1),
    axisMargin,
    1, //sliceWidth,
    overlay.height - 2 * axisMargin
  )
  ctx.fillStyle = sagittalColor
  ctx.fill()

  ctx.beginPath()
  ctx.rect(
    axisMargin,
    (overlay.height - scaledHeight) / 2 + scaledHeight - (sliceHeight * this.slices.coronal.currentSlice),
    overlay.width - 2 * axisMargin,
    1 //sliceHeight
  )
  ctx.fillStyle = coronalColor
  ctx.fill()
  ctx.clearRect((overlay.width - scaledWidth) / 2 +
    sliceWidth * (this.slices.sagittal.currentSlice - 1) - reticleSize / 2, (overlay.height - scaledHeight) / 2 + scaledHeight - (sliceHeight * this.slices.coronal.currentSlice) - reticleSize / 2, reticleSize, reticleSize)
}


export function getOverlayRenderer(plane) {
  switch (plane) {
    case "coronal":
      return coronalOverlayRenderer
    case "sagittal":
      return sagittalOverlayRenderer
    case "axial":
      return axialOverlayRenderer
  }
}


async function sagittalSliceRenderer() {
  let canvas = this.$refs.cvsImage,
    ctx = canvas.getContext("2d"),
    data = this.$store.state.imageData,
    zoomFactor = this.$store.state.zoomFactor,
    slice = this.$props.slice.currentSlice,
    sliceCount = this.$props.slice.sliceCount;

  let w = this.$store.state.slices.coronal.sliceCount,
    h = this.$store.state.slices.axial.sliceCount;

  canvas.width = canvas.parentElement.offsetWidth;
  canvas.height = canvas.parentElement.offsetHeight;
  ctx.clearRect(0, 0, canvas.width, canvas.height);


  var img_canvas = document.createElement('canvas'),
    img_ctx = img_canvas.getContext('2d'),
    img = img_ctx.createImageData(w, h);

  img_canvas.width = w;
  img_canvas.height = h;
  for (let i = 0; i < w * h; i++) {
    let loc = (sliceCount * i) + slice;
    let intensity = data[loc];
    setPixel(img, h - Math.floor(i / w), i % w, intensity, intensity, intensity, 255)
  }
  img_ctx.putImageData(img, 0, 0)
  var scaledHeight = img.height * zoomFactor;
  var scaledWidth = img.width * zoomFactor;
  ctx.drawImage(img_canvas, (canvas.width - scaledWidth) / 2, (canvas.height - scaledHeight) / 2, scaledWidth, scaledHeight)

  ctx.font = "14px sans-serif";
  ctx.textAlign = 'center';
  ctx.fillStyle = "white";
  ctx.textBaseline = 'middle';
  ctx.fillText('S', canvas.width / 2, 20);
  ctx.fillText('I', canvas.width / 2, canvas.height - 7);
  ctx.fillText('A', 10, canvas.height / 2);
  ctx.fillText('P', canvas.width - 10, canvas.height / 2);

}

async function coronalSliceRenderer() {
  let canvas = this.$refs.cvsImage,
    ctx = canvas.getContext("2d"),
    data = this.$store.state.imageData,
    zoomFactor = this.$store.state.zoomFactor,
    slice = this.$props.slice.currentSlice,
    sliceCount = this.$props.slice.sliceCount

  let w = this.$store.state.slices.sagittal.sliceCount,
    h = this.$store.state.slices.axial.sliceCount;

  canvas.width = canvas.parentElement.offsetWidth;
  canvas.height = canvas.parentElement.offsetHeight;
  ctx.clearRect(0, 0, canvas.width, canvas.height);


  var img_canvas = document.createElement('canvas'),
    img_ctx = img_canvas.getContext('2d'),
    img = img_ctx.createImageData(w, h);

  img_canvas.width = w;
  img_canvas.height = h;
  for (let i = 0; i < w * h; i++) {
    let loc = (sliceCount - slice) * w * h + i
    let intensity = data[loc];
    setPixel(img, i % w, Math.floor(i / w), intensity, intensity, intensity, 255)
  }

  img_ctx.putImageData(img, 0, 0)
  var scaledHeight = img.height * zoomFactor;
  var scaledWidth = img.width * zoomFactor;

  ctx.drawImage(img_canvas, (canvas.width - scaledWidth) / 2, (canvas.height - scaledHeight) / 2, scaledWidth, scaledHeight)

  ctx.font = "14px sans-serif";
  ctx.textAlign = 'center';
  ctx.fillStyle = "white";
  ctx.textBaseline = 'middle';
  ctx.fillText('S', canvas.width / 2, 20);
  ctx.fillText('I', canvas.width / 2, canvas.height - 7);
  ctx.fillText('R', 10, canvas.height / 2);
  ctx.fillText('L', canvas.width - 10, canvas.height / 2);

}

async function sagittalSliceRenderer2() {
  let canvas = this.$refs.cvsImage,
    ctx = canvas.getContext("2d"),
    data = this.$store.state.imageData,
    zoomFactor = this.$store.state.zoomFactor,
    slice = this.$props.slice.currentSlice,
    sliceCount = this.$props.slice.sliceCount;

  canvas.width = canvas.parentElement.offsetWidth;
  canvas.height = canvas.parentElement.offsetHeight;
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  let rows = this.$store.state.slices.sagittal.sliceCount,
    cols = this.$store.state.slices.coronal.sliceCount,
    sliceSize = rows * cols,
    sliceOffset = sliceSize * slice;

  let img_canvas = document.createElement('canvas'),
    img_ctx = img_canvas.getContext('2d'),
    img = img_ctx.createImageData(rows, cols);

  img_canvas.width = rows;
  img_canvas.height = cols;

  for (var row = 0; row < rows; row++) {
    var rowOffset = row * cols;

    for (var col = 0; col < cols; col++) {
      var offset = sliceOffset + rowOffset + col;
      var intensity = data[offset];
      img.data[(rowOffset + col) * 4] = intensity;
      img.data[(rowOffset + col) * 4 + 1] = intensity;
      img.data[(rowOffset + col) * 4 + 2] = intensity;
      img.data[(rowOffset + col) * 4 + 3] = 0xFF;
    }
  }

  img_ctx.putImageData(img, 0, 0)
  var scaledHeight = img.height * zoomFactor;
  var scaledWidth = img.width * zoomFactor;

  ctx.drawImage(img_canvas, (canvas.width - scaledWidth) / 2, (canvas.height - scaledHeight) / 2, scaledWidth, scaledHeight)

  ctx.font = "14px sans-serif";
  ctx.textAlign = 'center';
  ctx.fillStyle = "white";
  ctx.textBaseline = 'middle';
  ctx.fillText('S', canvas.width / 2, 20);
  ctx.fillText('I', canvas.width / 2, canvas.height - 7);
  ctx.fillText('A', 10, canvas.height / 2);
  ctx.fillText('P', canvas.width - 10, canvas.height / 2);

}


async function axialSliceRenderer() {
  let canvas = this.$refs.cvsImage,
    ctx = canvas.getContext("2d"),
    data = this.$store.state.imageData,
    zoomFactor = this.$store.state.zoomFactor,
    slice = this.$props.slice.currentSlice,
    sliceCount = this.$props.slice.sliceCount;

  let h = this.$store.state.slices.sagittal.sliceCount,
    w = this.$store.state.slices.coronal.sliceCount;


  canvas.width = canvas.parentElement.offsetWidth;
  canvas.height = canvas.parentElement.offsetHeight;
  ctx.clearRect(0, 0, canvas.width, canvas.height);


  var img_canvas = document.createElement('canvas'),
    img_ctx = img_canvas.getContext('2d'),
    img = img_ctx.createImageData(w, h);

  img_canvas.width = w;
  img_canvas.height = h;
  for (let i = 0; i < w * h; i++) {
    let loc = (sliceCount - slice) * w + (Math.floor(i / w) * w * h) + (i % w);
    let intensity = data[loc];
    setPixel(img, i % w, Math.floor(i / w), intensity, intensity, intensity, 255)
  }

  img_ctx.putImageData(img, 0, 0)
  var scaledHeight = img.height * zoomFactor;
  var scaledWidth = img.width * zoomFactor;

  ctx.drawImage(img_canvas, (canvas.width - scaledWidth) / 2, (canvas.height - scaledHeight) / 2, scaledWidth, scaledHeight)

  ctx.font = "14px sans-serif";
  ctx.textAlign = 'center';
  ctx.fillStyle = "white";
  ctx.textBaseline = 'middle';
  ctx.fillText('P', canvas.width / 2, 20);
  ctx.fillText('A', canvas.width / 2, canvas.height - 7);
  ctx.fillText('R', 10, canvas.height / 2);
  ctx.fillText('L', canvas.width - 10, canvas.height / 2);

}

export function getSliceRenderer(plane) {
  switch (plane) {
    case "coronal":
      return coronalSliceRenderer
    case "sagittal":
      return sagittalSliceRenderer
    case "axial":
      return axialSliceRenderer
  }
}

