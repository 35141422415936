import { createApp } from "vue";
import ElementPlus from "element-plus";
import "./element-variables.scss";
import App from "./App.vue";
import store from "./store";
import PrimeVue from "primevue/config";

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap";
// eslint-disable-next-line no-unused-vars
// import "jquery-ui";

window.mountedApp = createApp(App)
  .use(ElementPlus)
  .use(PrimeVue, { ripple: true })
  .use(store)
  .mount("#app");
