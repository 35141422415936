<template>
  <div class="parameter-group-pane">
    On a real scanner, this tab has information about slice placement and the
    locations of saturation bands.
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
</style>