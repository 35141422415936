<template>
  <el-tabs class="inner-tab" type="card">
    <el-tab-pane label="Common">
      <ul class="parameter-group">
        <li>
          <label>FoV read</label>
          <el-input-number
            size="mini"
            controls-position="right"
            v-model="$store.state.parameters.fov_read"
          />
          mm
        </li>
        <li>
          <label>FoV phase</label>
          <el-input-number
            size="mini"
            controls-position="right"
            v-model="$store.state.parameters.fov_phase"
            :precision="1"
            :step="0.1"
            :min="0"
            :max="100"
          />
          %
        </li>
        <li>
          <label>Slice thickness</label>
          <el-input-number
            size="mini"
            controls-position="right"
            v-model="$store.state.parameters.slice_thickness"
            :precision="2"
            :step="0.01"
          />
          mm
        </li>
        <li>
          <label>Base resolution</label>
          <el-input-number
            size="mini"
            controls-position="right"
            v-model="$store.state.parameters.base_resolution"
          />
        </li>
        <li>
          <label>Phase resolution</label>
          <el-input-number
            size="mini"
            controls-position="right"
            v-model="$store.state.parameters.phase_resolution"
            :min="0"
            :max="100"
          />
          %
        </li>
        <li>
          <label>Phase partial Fourier</label>
          <el-select
            v-model="$store.state.parameters.phase_partial_fourier"
            placeholder="Select"
            size="mini"
          >
            <el-option
              v-for="item in phase_partial_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </li>
      </ul>
    </el-tab-pane>
    <el-tab-pane label="iPAT">
      <ul class="parameter-group">
        <li>
          <label>PAT Mode</label>
          <el-select
            v-model="$store.state.parameters.pat_mode"
            placeholder="Select"
            size="mini"
          >
            <el-option
              v-for="item in pat_modes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </li>
        <li v-if="$store.state.parameters.pat_mode == 1">
          <label>Acceleration Factor</label>
          <el-input-number
            size="mini"
            controls-position="right"
            v-model="$store.state.parameters.acceleration_factor"
          />
        </li>
      </ul>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
export default {
  data() {
    return {
      phase_partial_options: [
        {
          value: 0,
          label: 'Off'
        },
        {
          value: 1,
          label: '7/8'
        },
        {
          value: 2,
          label: '6/8'
        },
        {
          value: 3,
          label: '5/8'
        }
      ],
      pat_modes: [
        {
          value: 0,
          label: 'None'
        },
        {
          value: 1,
          label: 'GRAPPA'
        },
        {
          value: 2,
          label: 'SENSE'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
</style>