<template>
  <el-tabs class="inner-tab" type="card">
    <el-tab-pane label="Coils">
      <ul class="parameter-group">
        <li>
          <label>Active Coil</label>
          <el-radio-group v-model="$store.state.parameters.coil" size="small">
            <el-radio-button label="Head Recieve Coil"></el-radio-button>
            <el-radio-button label="Body Coil"></el-radio-button>
          </el-radio-group>
        </li>
      </ul>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
export default {}
</script>

<style>
.parameter-group .el-radio-group label {
  padding: 0px;
}
</style>