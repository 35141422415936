import { createStore } from "vuex";
import { getNifti } from "../modules/mriSim.js";

export default createStore({
  state: {
    systemState: "WAITING",
    isLoading: false,
    isLoaded: false,
    imageData: [],
    imageHeader: undefined,
    slices: {
      coronal: { currentSlice: 1, label: "Coronal", dimIndex: 1, plane: "coronal" },
      sagittal: { currentSlice: 1, label: "Sagittal", dimIndex: 2, plane: "sagittal" },
      axial: { currentSlice: 1, label: "Axial", dimIndex: 3, plane: "axial" },
    },
    zoomFactor: 1,
    parameterActiveTab: "Program",
    parameters: {
      bandwidth: 2604,
      fov: 0.064,
      tr: 500,
      te: 50,
      resolution: 64,
      subject: "human",
      pulse_sequence: "Flash",
      inversion_time: 1100,
      alpha: 10,
      fov_read: 192,
      fov_phase: 100.0,
      slice_thickness: 3,
      base_resolution: 64,
      phase_resolution: 100,
      phase_partial_fourier: 0,
      pat_mode: 0,
      acceleration_factor: 2,
      coil: "Body Coil",
      orientation: "transverse",
      phase_direction: "lr",
      slices: 3,
      flip_angle: 60,
      ti: 0,
      mag_prep: 0,
      reconstruction: 0,
    },
    subjects: [
      { value: "human", label: "Human" },
      { value: "oval", label: "Oval", disabled: true },
      { value: "pineapple", label: "Pineapple", disabled: true },
    ]
  },
  getters: {
    statusMessage: state => {
      if (state.systemState == "WAITING") {
        return "Waiting for user instruction"
      } else if (state.systemState == "LOADING") {
        return "Scanning..."
      }
    },
    numberOfSlices: state => dimension => {
      if (state.imageHeader) {
        return state.imageHeader.dims[dimension];
      } else {
        return 0;
      }
    },
    phaseDirections: state => {
      return [
        {
          value: 'lr',
          label: 'L>>R',
          disabled: state.parameters.orientation == "sagittal"
        },
        {
          value: 'rl',
          label: 'R>>L',
          disabled: state.parameters.orientation == "sagittal"
        },
        {
          value: 'pa',
          label: 'P>>A',
          disabled: state.parameters.orientation == "coronal"
        },
        {
          value: 'ap',
          label: 'A>>P',
          disabled: state.parameters.orientation == "coronal"
        },
        {
          value: 'hf',
          label: 'H>>F',
          disabled: state.parameters.orientation == "transverse"
        },
        {
          value: 'FH',
          label: 'F>>H',
          disabled: state.parameters.orientation == "transverse"
        },
      ]
    },
  },
  mutations: {
    SET_LOADING_STATUS(state, status) {
      state.isLoading = status;
      if (status === true) {
        state.isLoaded = false;
        state.imageData = [];
        state.systemState = "LOADING";
      }
    },
    SET_IMAGE_DATA(state, data) {
      state.imageData = data;
      state.isLoaded = true;
      state.systemState = "WAITING";
    },
    SET_IMAGE_HEADER(state, header) {
      state.imageHeader = header;

      // Set orthogonal plane index.
      state.slices.coronal.dimIndex = 1;
      state.slices.axial.dimIndex = 2;
      state.slices.sagittal.dimIndex = 3;


      state.slices.coronal.sliceCount = header.dims[state.slices.coronal.dimIndex];
      state.slices.coronal.currentSlice = Math.floor(header.dims[state.slices.coronal.dimIndex] / 2);
      state.slices.sagittal.sliceCount = header.dims[state.slices.sagittal.dimIndex];
      state.slices.sagittal.currentSlice = Math.floor(header.dims[state.slices.sagittal.dimIndex] / 2);
      state.slices.axial.sliceCount = header.dims[state.slices.axial.dimIndex];
      state.slices.axial.currentSlice = Math.floor(header.dims[state.slices.axial.dimIndex] / 2);
    }
  },
  actions: {
    async fetchImage(context) {
      var response = null;

      context.commit("SET_LOADING_STATUS", true);
      response = await getNifti(
        // "https://mrisim.psych.umn.edu/static/img/MNI152_T1_1mm_nifti2.nii.gz"
        // "static/img/1010_brain_mr_06.nii.gz"
        "static/img/human_T2.nii"
      );
      if (response.imageArrayBuffer) {
        context.commit("SET_IMAGE_DATA", response.imageArrayBuffer);
      }
      if (response.imageHeader) {
        context.commit("SET_IMAGE_HEADER", response.imageHeader);
      }
      if (response.imageExtension) {
        context.commit("SET_IMAGE_EXTENSION", response.imageExtension);
      }
      context.commit("SET_LOADING_STATUS", false);
    }
  },
  modules: {}
});
